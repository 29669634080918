<template>
  <div>
    <b-row>
      <b-col>
        <validation-observer ref="requestForm">
          <b-form @submit.prevent="onSubmit">
            <b-card no-body>
              <b-card-header>
                <div>
                  <b-card-title>
                    ข้อมูลทางเทคนิคที่จะเชื่อมต่อกับระบบ Sandbox
                  </b-card-title>
                </div>
              </b-card-header>
              <hr class="m-0">
              <b-card-body>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="รูปแบบของ Wallet"
                      label-for="integrateType"
                    >
                      <b-form-select
                        id="integrateType"
                        v-model="formData.integrateType"
                        :options="options"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="callbackUrl"
                      rules="url"
                    >
                      <b-form-group
                        label="Callback URL"
                        label-for="callbackUrl"
                      >
                        <b-form-input
                          id="callbackUrl"
                          v-model="formData.callbackUrl"
                          placeholder="ตัวอย่าง : https://umoregame888.com/umore/"
                          aria-describedby="input-live-help input-live-feedback"
                          :state="errors[0] ? false : null"
                        />
                        <b-form-invalid-feedback id="input-live-feedback">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <label for="input-none">Whitelist IP</label>
                    <b-row>
                      <b-col
                        v-for="(ip, index) in whitelist"
                        :key="index"
                        cols="6"
                        class="pb-1"
                      >
                        <b-form-input
                          v-model="whitelist[index]"
                          :placeholder="`IP Address ${index + 1}`"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
            <b-row>
              <b-col class="text-center">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  to="/"
                  variant="outline-primary"
                  class="mr-1"
                >
                  ย้อนกลับ
                </b-button>
                <b-button
                  variant="primary"
                  type="submit"
                >
                  ส่งคำขอ
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required, url } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  directives: {
    Ripple,
  },
  components: {},
  props: {
    agentId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      url,
      required,

      isShowPassword: false,
      formData: {
        integrateType: 0,
        environment: 0,
        callbackUrl: '',
        whitelistIp: '',
      },
      whitelist: ['', '', '', '', '', ''],

      options: [
        { text: 'Seamless', value: 0 },
        { text: 'Transfer', value: 1 },
      ],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.request.isSendingRequest,
    }),
    ...mapGetters(['environment']),
  },
  methods: {
    ...mapActions(['sendRequest']),
    onSubmit() {
      this.$refs.requestForm.validate().then(async (success) => {
        if (success) {
          this.formData.environment = +this.environment
          this.formData.whitelistIp = this.whitelist
            .filter((item) => !!item)
            .join(',')
          this.sendRequest(this.formData)
        }
      })
    },
  },
}
</script>
